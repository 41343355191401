.slider-container {
  position: relative;
}

.background-images {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.image-container {
  position: relative;
}

.button-container {
  position: absolute;
  width: 100%;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 1;
}

.button-container button {
  background-color: transparent;
  width: 100%;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.5s;
}

.button-container button:hover {
  background-color: transparent;
  color: #fe7339;
}

.button-container button.active {
  color: #fe7339;
  font-weight: bolder;
  font-size: 10px;
}

.vertical-line {
  height: 30px;
  width: 1px;
  background-color: #848486;
  margin: 0 10px;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #848486;
  position: absolute;
  top: 60px;
  left: 0;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.overlayOracle {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.text {
  font-size: 18px;
  line-height: 1.5;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  gap: 19px;
  width: 20rem;
}

.icon-container-oracle {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  gap: 19px;
  width: 20rem;
}

.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 246, 246, 0.3);
  backdrop-filter: blur(0.2px);
  padding: 10px 20px;
  border-radius: 12px;
  width: 85px;
}
.icon-box-oracle {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: rgba(255, 246, 246, 0.3);
  backdrop-filter: blur(0.2px);
  padding: 10px 20px;
  border-radius: 12px;
  width: 20rem;
}

.icon-box img {
  width: 35px;
  height: 35px;
}

.icon-box p {
  margin: 0px;
}

.icon-box-oracle p {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 0;
}
.icon-box-oracle label {
  font-weight: 600 !important;
}

.learnMore {
  background-color: #000410;
  color: #fff;
  padding: 5px 30px;
  border-radius: 5px;
  border: 1px solid #fe7339;
  width: 10rem;
}

.learnMore:hover,
.learnMore:focus {
  animation: pulse 1s;
  border-radius: 5px;
  box-shadow: 0 0 0 2em transparent;
  color: #f2652a;
}

@media (max-width: 768px) {
  .button-container {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    font-size: 10px;
  }
  .button-container button {
    padding: 10px;
    margin: 0px;
  }

  .overlay {
    top: 35%;
    transform: translate(-50%, -50%);
    text-align: start;
    width: 88%;
    left: 50%;
    gap: 10px;
  }
  .overlayOracle {
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: start;
    width: 88%;
    left: 50%;
    gap: 2rem;
  }

  .text {
    font-size: 14px;
  }

  .icon-box img {
    width: 30px;
    height: 30px;
  }

  .icon-box p {
    font-size: 12px;
    margin: 0px;
  }

  .learnMore {
    width: 100%;
    padding: 10px;
  }

  .icon-container {
    top: 75%;
    left: 50%;
  }
  .icon-container-oracle {
    display: none;
  }
  .oracleImages {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .oracleImages h2 {
    font-size: 12px;
  }
}

.learnMore {
  background-color: #000410;
  color: #fff;
  padding: 5px 30px;
  border-radius: 5px;
  border: 1px solid #fe7339;
  width: 10rem;
}

.learnMore:hover,
.learnMore:focus {
  animation: pulse 1s;
  border-radius: 5px;
  box-shadow: 0 0 0 2em transparent;
  color: #f2652a;
}

@keyframes learnMore {
  0% {
    box-shadow: 0 0 5px 5px rgba(248, 220, 220, 0.5);
  }
}

.offset {
  box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
  }
}
