.SAPImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.secondSectionSAP {
  padding: 5rem 8rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}

.secondSectionSAP h1 {
  font-weight: bold;
}

.SAPThird {
  padding: 5rem 8rem;
}

.SAPSection {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 49%;
}

.SAPMAINSEC {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}


.SAPMAINSEC ul {
  list-style-type: disc;
}

.SAPMAINSEC li {
  margin: 0px 16px;
}

@media (max-width: 768px) {
  .secondSectionSAP {
    padding: 20px;
    text-align: start;
  }
  .SAPThird {
    padding: 20px;
  }
  .SAPSection {
    width: 100%;
  }
}
