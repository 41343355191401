.AIRPAimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.AiRpa-section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.AiRpa-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.AiRpa-overlay {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.AiRpa-Subtext {
  font-size: 5rem;
}

@media (max-width: 768px) {
  .AiRpa-overlay {
    display: none;
  }
    .secondSectionCM {
    padding: 20px;
  }
}


.AiSection {
  padding: 5rem 8rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: start;
}

.AiSection h1 {
  font-weight: bold;
}
