.contentBackground {
  background-image: url(../../../Assets/images/lightOrangle.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100vh;
}

.undefinePage {
  width: 100%;
  height: 100%;
  background: #fffbfbab;
}
