.oracle-section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.oracle-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.oracle-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.oracle-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.overlay-oracle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.overlay-subtext {
  font-size: 1.5rem;
  margin-top: 10px;
}

.small-overlay-section {
  display: none;
}

.OrcaleBusinessSection {
  padding: 5rem 0;
  text-align: center;
}

.OrcaleContentSection {
  padding: 5rem 3rem;
  text-align: center;
}

.OrcaleULSection {
  padding: 0rem 5rem 5rem 5rem;
}

.UlSection p {
  margin: 0px;
}

.UlSection ul {
  list-style-type: disc;
}

.VerticalContainer ul {
  list-style-type: disc;
}

.VerticalContainer li {
  margin: 0px 16px 13px 16px;
}

.UlSection li {
  margin: 0px 1rem;
}

.MainUlSection {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.UlSection {
  background-color: rgb(255 246 246 / 15%);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  padding: 10px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.VerticalContainer {
  background-color: rgb(255 246 246 / 15%);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  padding: 10px 15px;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.threeSections {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 65%;
}

.AllSections {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-image: url(../../../../Assets/images/lightOrangle.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* Media Query for Small Devices */
@media (max-width: 768px) {
  .OrcaleULSection {
    display: none;
  }

  .ContentSlider {
    display: block;
  }
}

@media (min-width: 769px) {
  .ContentSlider {
    display: none;
  }
}

@media (max-width: 768px) {
  .overlay-oracle {
    display: none;
  }

  .small-overlay-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: center;
  }

  .OrcaleBusinessSection {
    text-align: start;
    padding: 1rem 0;
  }

  .OrcaleContentSection {
    text-align: start;
    padding: 1rem;
  }
  .VerticalContainer {
    width: 100%;
  }
  .VerticalContainer li {
    margin: 0px 16px;
  }
}

.slick-dots {
  bottom: 0px !important;
  display: block;
  width: 90% !important;
}

.slick-dots li.slick-active button:before {
  color: #f2652a !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  color: #60636a !important;
  opacity: 1 !important;
}


.slick-dots li button:before {
  display: none;
}

.slick-dots li {
  display: inline-block;
  width: 22%;
  height: 5px; 
  background: #60636a; 
  margin: 0 5px; 
  border-radius: 12px;
}

.slick-dots li.slick-active {
  background: #ff7f50; 
}   