.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-title {
  font-size: 24px;
  font-weight: 700;
}

.nav-buttons {
  display: flex;
  gap: 10px;
}

.nav-buttons button {
  background: #eee;
  color: #110707;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 50%;
}

.nav-buttons button:hover {
  background: #f2652a;
  color: #fff;
}

.slick-slider {
  position: relative;
}

.post-card {
  overflow: hidden;
  text-align: start;
}

.post-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}

.post-content {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  padding: 15px;
}

.post-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.post-description {
  font-size: 14px;
  margin-bottom: 15px;
}

.post-footer {
  display: flex;
  align-items: center;
}

.author-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.author-info {
  display: flex;
  flex-direction: column;
}

.author-name {
  font-size: 14px;
  font-weight: bold;
}

.post-date {
  font-size: 12px;
}

@media (max-width: 768px) {
  .post-card {
    margin: 10px;
  }

  .post-image {
    height: 150px;
  }

  .post-title {
    font-size: 16px;
  }

  .post-description {
    font-size: 12px;
  }

  .author-name {
    font-size: 12px;
  }

  .post-date {
    font-size: 10px;
  }
}




[dir="rtl"] .prev-button {
  transform: scaleX(-1);
}
[dir="rtl"] .next-button {
  transform: scaleX(-1);
}
