.bg-image-section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.crm-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.crm-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-crm {
  position: absolute;
  max-width: 100%;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.crm-second-section {
  padding: 5rem;
  text-align: center;
}

@media (max-width: 768px) {
  .crm-second-section {
    padding: 20px;
    text-align: start;
  }

  .Bi-content-Child {
    flex-direction: column;
  }
  .Bi-content-section {
    padding: 20px !important;
  }
  .Bi-subChild {
    width: 100% !important;
  }
  .Bi-child-ulSection {
    width: 100% !important  ;
  }
}

.Bi-content-section {
  padding: 5rem 3rem;
}

.Bi-content-Child {
  display: flex;
  justify-content: space-around;
}

.Bi-subChild {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Bi-child-ulSection {
  width: 40%;
}

.Bi-customer ul {
  list-style-type: disc;
}

.Bi-customer li {
  margin: 0px 16px;
}
