.privacy-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.small-screen {
  width: 100%;
  height: auto;
}

.TermsOverlay {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  margin: auto;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  color: #fff;
}

.TermsOverlay h1 {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .large-screen {
    display: none;
  }

  .TermsOverlay {
    top: 50%;
    left: 50%;
    width: 20rem;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
  }
}
