label {
  display: block;
  margin-bottom: 0.5rem;
}

input,
textarea {
  margin-bottom: 5px !important;
  border-radius: 6px;
  border: 1px solid rgb(213, 212, 212);
}

.min-h-screen {
  min-height: 100vh;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}

[dir="rtl"] .planetImage {
  transform: scaleX(-1);
}

.CarosuelContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
}

.CarosuelContainer .image-wrapper {
  position: relative;
  overflow: hidden;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: width ease-in-out 1s;
}

.CarosuelContainer .image-wrapper.first {
  width: 40%;
}

.CarosuelContainer .image-wrapper:not(.first) {
  width: 20%;
}

.CarosuelContainer .image-wrapper:hover {
  width: 40%;
}

.CarosuelContainer .image-wrapper:not(:hover):not(.first) {
  width: 20%;
}

.CarosuelContainer:hover .first:not(:hover) {
  width: 20%;
}

.CarosuelContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  filter: brightness(0.5);
  transition: filter 0.5s ease-in-out, width 0.5s ease-in-out;
}

.image-name {
  position: absolute;
  top: 25%;
  left: 40%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  z-index: 10;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 5;
}

.image-overlay.visible {
  opacity: 1;
}

.details {
  margin-top: 50px;
  color: white;
  text-align: start;
  background-color: rgba(11, 11, 11, 39%);
  border-radius: 20px;
  padding: 10px;
  color: white;
  width: 20rem;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.detail-item svg {
  margin-right: 10px;
  font-size: 1.2rem;
}

/* Responsive layout for small devices */
@media (max-width: 768px) {
  .CarosuelContainer {
    flex-direction: column;
    height: auto;
  }

  .CarosuelContainer .image-wrapper.first {
    width: 100%;
  }

  .CarosuelContainer .image-wrapper:not(.first) {
    width: 100%;
  }

  .CarosuelContainer .image-wrapper:hover {
    width: 100%;
  }

  .CarosuelContainer .image-wrapper:not(:hover):not(.first) {
    width: 100%;
  }

  .CarosuelContainer:hover .first:not(:hover) {
    width: 100%;
  }

  .CarosuelContainer .image-wrapper {
    width: 100%;
    height: 300px;
  }

  .CarosuelContainer img {
    height: 100%;
  }

  .image-name {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1rem;
    font-weight: bold;
    z-index: 10;
    width: 20rem;
    text-align: center;
  }

  .details {
    width: 18rem;
  }

  .detail-item {
    flex-direction: row;
    align-items: center;
  }

  .detail-item svg {
    margin-right: 5px;
  }
}
