.slider-container {
  padding: 20px;
}

.service-slide {
  display: flex !important;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  gap: 20rem;
}

.service-content {
  display: flex;
  align-items: start;
  width: 100%;
  gap: 20px;
  padding: 10px;
  border-radius: 10px;
}

.service-image {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.service-title {
  font-size: 1rem;
  font-weight: 600;
}


