.Digitalimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .DigitalSection {
    padding: 20px !important;
  }
  .DigitalTransformation {
    padding: 20px !important;
    flex-direction: column;
  }
  .Digital-child {
    flex-wrap: wrap;
  }
}

.DigitalSection {
  padding: 5rem 8rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: start;
}

.Digi-subtext {
  font-weight: bold;
}

.DigitalTransformation {
  padding: 0rem 8rem 5rem 8rem;
  display: flex;
  gap: 20px;
  justify-content: center;
  text-align: start;
  align-items: center;
}

.Digital-child {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 250px;
  height: 150px;
}
