.founder-section {
  height: 100vh;
}
.founder-image-background {
  object-fit: cover;
}

.image-container-client {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.testimonial-card {
  position: absolute;
  padding: 30px;
  border-radius: 20px;
  max-width: 600px;
  width: 80%;
  text-align: center;
  z-index: 3;
  background-color: rgba(255, 246, 246, 0.077);
  backdrop-filter: blur(20px);
  border: 1px solid #848486;
}

.quote-icon {
  font-size: 50px;
  color: #fe7339;
}

.testimonial-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.client-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.client-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.client-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.client-title {
  font-size: 14px;
}

.nav-arrows {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .image-container-client {
    width: 100%;
    height: 80%;
  }
  .testimonial-card {
    width: 100%;
    padding: 20px;
  }

  .quote-icon {
    font-size: 40px;
  }

  .testimonial-text {
    font-size: 14px;
  }

  .client-image {
    width: 50px;
    height: 50px;
  }

  .client-name {
    font-size: 16px;
  }

  .client-title {
    font-size: 12px;
  }

  .nav-arrows {
    padding: 0 10px;
  }
}
