.aboutus-image-overlay {
  position: absolute;
  top: 40%;
  width: 100%;
}

.full-screen-image-aboutus {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.full-screen-Aboutbg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.OurMission {
  padding: 5rem 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OurVission {
  padding: 5rem 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.MissionContent {
  width: 60%;
}

.VissionContent {
  width: 60%;
}

.MissionContent li {
  list-style-type: disc;
  margin: 0px 25px;
}

@media (max-width: 568px) {
  .aboutus-image-overlay {
    display: none;
  }

  .about-image-overlay {
    display: none !important;
  }

  .OurMission {
    padding: 0px;
    flex-direction: column-reverse;
  }

  .MissionContent {
    width: 100%;
    padding: 20px;
  }

  .OurVission {
    padding: 0px;
    flex-direction: column;
  }

  .VissionContent {
    width: 100%;
    padding: 20px;
  }
  .overlay-title-about {
    width: 100% !important;
  }
  .overlay-title-about p {
    display: none;
  }

  .aboutuspaetnerImage {
    height: 90px !important;
    width: 90px !important;
  }
}

.image-container {
  position: relative;
}

.about-image-overlay {
  position: absolute;
  top: 30%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  text-align: center;
  color: #fff;
}

.full-screen-AboutLast {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.about-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.overlay-title-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 1rem auto;
}

.overlay-title-about li {
  list-style-type: disc;
  margin: 0px 25px;
}

.aboutuspaetnerImage {
  border-radius: 50%;
  object-fit: contain;
  padding: 20px;
  height: 180px;
  width: 180px;
}
