.BI-section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.BI-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay-Bi {
  position: absolute;
  max-width: 25rem;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  text-align: start;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.BI-Logo {
  width: 200px;
}

.BI-overlay-subtext {
  font-size: 1.5rem;
  margin-top: 10px;
}

.Sm-Bi-section {
  display: none;
}

@media (max-width: 768px) {
  .overlay-Bi {
    display: none;
  }
  .Sm-Bi-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: start;
  }
  .Bi-content-Child {
    flex-direction: column;
  }
  .Bi-content-section {
    padding: 20px !important;
  }
  .Bi-subChild {
    width: 100% !important;
  }
  .Bi-child-ulSection {
    width: 100% !important  ;
  }
}

.Bi-content-section {
  padding: 5rem 3rem;
}

.Bi-content-Child {
  display: flex;
  justify-content: space-around;
}

.Bi-subChild {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Bi-child-ulSection {
  width: 40%;
}

.Bi-customer ul {
  list-style-type: disc;
}

.Bi-customer li {
  margin: 0px 16px;
}


