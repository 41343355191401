.Mainsection {
  display: flex;
  justify-content: space-between;
}

.contentSection {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 20px;
  align-items: flex-start;
}

.pulse {
  padding: 7px 40px;
  border: 1px solid #f2652a;
  border-radius: 5px;
}

.pulse:hover,
.pulse:focus {
  animation: pulse 1s;
  border-radius: 5px;
  box-shadow: 0 0 0 2em transparent;
  color: #f2652a;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px 5px rgba(248, 220, 220, 0.5) }
}

.offset {
  box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
  }
}

@media (max-width: 768px) {
  .contentSection {
    padding: 20px;
  }
  .Mainsection {
    flex-direction: column-reverse;
  }
  .secondSection {
    padding: 20px !important;
  }
  .ImageSection{
  width: 130px;
}
}

.secondSection {
  padding: 5rem 8rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


@keyframes shadow-animation {
  0% {
    box-shadow: 0 0 5px 5px #FE7339;
  }
  50% {
    box-shadow: 0 0 10px 10px #e7d4cc;
  }
  100% {
    box-shadow: 0 0 5px 5px #e9e7e6;
  }
}

.section-wrapper {
  position: relative;
  overflow: hidden;
  color: black;
  text-align: center;
  animation: shadow-animation 5s infinite alternate;
}

.section-wrapper .content {
  position: relative;
  z-index: 1;
}






/* .slick-slide img {
  max-width: 100%; 
  transition: transform 0.5s; 
 }

.slick-slide.slick-center img{
  transform: scale(1.8);
} */


.slider-container {
  width: 100%;
  margin: auto;
}

.slider__item {
  padding: 10px;
  text-align: center;
  border-radius: 4px;
}

.center .slick-center .slider__item {
  transform: scale(1.2);
  transition: transform 0.3s;
}
