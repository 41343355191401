.blog-image-overlay {
  position: absolute;
  top: 90%;
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

.overlay-title {
  font-size: 2rem;
  margin: 0;
}

.post-breadcrumb {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: gray;
}

.breadcrumb-link {
  text-decoration: underline;
  color: gray;
}

.breadcrumb-link:hover {
  text-decoration: underline;
  color: gray;
}

.post-author {
  display: flex;
  align-items: center;
  gap: 20px;
}

.author-image-blog {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.author-info {
  display: flex;
  flex-direction: column;
}

.author-name {
  font-size: 1rem;
  font-weight: bold;
}

.post-date {
  font-size: 0.9rem;
  color: gray;
}

.post-content-blog ul {
  list-style-type: disc;
}

@media (max-width: 768px) {
  .overlay-title {
    display: none;
  }
  .post-breadcrumb span {
    font-size: 30px;
    padding: 0px 50px 0px 0px;
  }
  .breadcrumb-link {
    display: none;
  }
  .post-blogs {
    padding: 20px !important;
  }
}

.post-blogs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem;
}

.glowContainer {
  background-image: url(../../../Assets/images/glow.svg);
  width: 100%;
  object-fit: cover;;
  padding-bottom: 5px;
}

.newsletter-container {
  text-align: center;
}

.newsletter-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.newsletter-input {
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
  width: 300px;
  border: 1px solid #000410;
}

.newsletter-button {
  padding: 15px;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #ff6b35;
  color: white;
}

.newsletter-button:hover {
  background-color: #ff4e00;
}


[dir="rtl"] .newsletter-button {
  transform: scaleX(-1);
}
