.privacy-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.small-screen {
  width: 100%;
  height: auto;
}

.PrivacyOverlay {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 30rem;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  color: #fff;
}

.PrivacyOverlay h1 {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .large-screen {
    display: none;
  }

  .PrivacyOverlay {
    top: 50%;
    left: 50%;
    width: 20rem;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
  }
}

/* 
 .privacy-container {
  position: relative;
  width: 100%;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.privacy-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  z-index: -1; 
}

.PrivacyOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  text-align: center;
}

.text-content {
  margin: 20px 0;
}


.section-content {
  padding: 20px;
}

h3 {
  margin-bottom: 10px;
} */
