.nav-link:hover {
  border-bottom: 2px solid #f2652a;
  color: #f2652a !important;
}

.nav-link.active {
  border-bottom: 2px solid #f2652a;
  color: #f2652a !important;
  font-weight: 500;
}

.no-hover:hover {
  border-bottom: none;
}

.container-fluid {
  margin: 0px 35px !important;
}

@media (max-width: 768px) {
  .container-fluid {
    margin: 0px !important;
  }
}

.ProductLinks {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #f2652a;
  cursor: pointer;
}

[dir="rtl"] .FaArrowRightLong {
  transform: scaleX(-1);
}

.prodcut-vertical-line {
  width: 1px;
  background-color: #848486;
  margin: 0 10px;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-Items {
  display: flex;
  gap: 5px;
  align-items: start;
}

@media (max-width: 768px) {

  .Product-Items {
    flex-direction: column;
  }
}

.Product-Items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
}


.ServicesLink:hover{
  color: #f2652a;
  text-decoration: underline !important;
  cursor: pointer;


}