.full-screen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-title {
  font-size: 24px;
  font-weight: 700;
}

.nav-buttons {
  display: flex;
  gap: 10px;
}

.nav-buttons button {
  background: #eee;
  color: #110707;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 50%;
}

.nav-buttons button:hover {
  background: #f2652a;
  color: #fff;
}

.slick-slider {
  position: relative;
}

.post-card-blog {
  overflow: hidden;
  text-align: start;
}

.post-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}

.blog-content {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  padding: 15px;
  border-radius: 0px 0px 20px 20px;
  height: 250px;
}

.post-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.post-description {
  font-size: 14px;
  margin-bottom: 15px;
}

.post-footer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.author-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.author-name {
  font-size: 14px;
  font-weight: bold;
}

.post-date {
  font-size: 12px;
}

@media (max-width: 768px) {
  .post-image {
    height: 150px;
  }

  .post-title {
    font-size: 16px;
  }

  .post-description {
    font-size: 12px;
  }

  .author-name {
    font-size: 12px;
  }

  .post-date {
    font-size: 10px;
  }
  .blogContainer {
    flex-direction: column;
  }
  .blogButton {
    padding: 5px 20px !important;
  }
}

.blogContainer {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.blogButton {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 5px 30px;
  border-radius: 5px;
}

.blogactive {
  background-color: #f2652a;
  font-weight: bold;
  border: 1px solid #f2652a;
  color: white;
}
.blogButton:hover {
  background-color: #f2652a;
  border: 1px solid #f2652a;
  font-weight: bold;
  color: white;
}

