
#footer {
  margin-bottom: 0px;
}
.main-footer {
  bottom: 0px;
}

.main-footer {
  padding: 35px 0;
  display: flex;
  justify-content: space-around;
}

.main-footer ul {
  list-style: none;
}

.main-footer h1 {
  font-size: 22px;
  line-height: 117%;
  margin-bottom: 10px;
  font-weight: 500;
}

.main-footer h2 {
  font-weight: 500;
}

.main-footer ul li a {
  text-decoration: none;
}

footer {
  border-top: 1px solid #adadb1;
  font-size: 17px;
  padding: 15px 35px;
  text-align: start;
}

footer a {
  text-decoration: none;
}

.logoinfo p {
  font-size: 17px;
  margin-top: 5px;
}

.contact-details {
  margin-top: 20px;
}
.contact-details label {
  max-width: 36rem !important;
}

.contact-details li {
  list-style: none;
  margin: 10px 0;
}

.contact-details li a {
  text-decoration: none;
}

.contact-details .fa {
  margin-right: 10px;
}

ul {
  padding-left: 0px !important;
}

.MainMenu ul li {
  padding: 5px 0;
}
.MainMenu li {
  text-decoration: none;
}

@media only screen and (max-width: 749px) {
  .main-footer {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .info {
    padding: 20px 0;
  }
}

@media (max-width: 480px) {
  .main-footer {
    grid-template-columns: 1fr;
  }

  .sociallogos {
    padding: 20px 0;
  }

  .MainMenu {
    padding: 20px 0;
  }
}
ul {
  padding-left: 0px;
}

.logobox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navLinksfooterhome:hover {
  border-bottom: 2px solid #f2652a;
  color: #f2652a;
  max-width: 40%;
}

.navLinksfooterhome.active {
  border-bottom: 2px solid #f2652a;
  color: #f2652a !important;
  font-weight: 500;
  max-width: 40%;
}

.navLinksfooterServices:hover {
  border-bottom: 2px solid #f2652a;
  color: #f2652a;
  max-width: 50%;
}

.navLinksfooterServices.active {
  border-bottom: 2px solid #f2652a;
  color: #f2652a !important;
  font-weight: 500;
  max-width: 50%;
}

.navLinksfooterAbout:hover {
  border-bottom: 2px solid #f2652a;
  color: #f2652a;
  max-width: 60%;
}

.navLinksfooterAbout.active {
  border-bottom: 2px solid #f2652a;
  color: #f2652a !important;
  font-weight: 500;
  max-width: 60%;
}




.navLinksfooterContactus.active {
  border-bottom: 2px solid #f2652a;
  color: #f2652a !important;
  font-weight: 500;
  max-width: 70%;
}
.navLinksfooterContactus:hover {
  border-bottom: 2px solid #f2652a;
  color: #f2652a;
  max-width: 70%;
}
.navLinksfooterPrivacy.active {
  border-bottom: 2px solid #f2652a;
  color: #f2652a !important;
  font-weight: 500;
  max-width: 75%;
}
.navLinksfooterPrivacy:hover {
  border-bottom: 2px solid #f2652a;
  color: #f2652a;
  max-width: 75%;
}

.navLinksfooter.active {
  border-bottom: 2px solid #f2652a;
  color: #f2652a !important;
  font-weight: 500;
  max-width: 100%;
}
.navLinksfooter:hover {
  border-bottom: 2px solid #f2652a;
  color: #f2652a;
  max-width: 100%;
}


.no-hover:hover {
  border-bottom: none;
}
