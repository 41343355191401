.collection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection .content {
  position: relative;
  height: 23rem;
  width: 20rem;
  border-radius: 0.7rem;
  overflow: hidden;
}

.content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlaySwiper {
  position: absolute;
  top: 80%;
  left: 78%;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  width: 30rem;
  flex-direction: column;
  gap: 30px;
}

.text-content {
  text-align: start;
}

.text-content h3 {
  font-size: 1.7rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.text-content p {
  max-width: 65%;
  font-size: 0.8rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  .content {
    width: 80%;
  }

  .content img {
    height: auto;
  }

  .text-content h3 {
    font-size: 1.2rem;
  }

  .text-content p {
    font-size: 0.7rem;
    max-width: 70%;
  }
  .overlaySwiper {
    top: 85%;
    transform: translate(-50%, -50%);
    text-align: start;
    width: 88%;
    left: 60%;
    background: rgba(0, 0, 0, 70%);
  }
}

.swiper-wrapper {
  justify-content: flex-start;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}

.swiper-carousel {
  padding-bottom: 32px;
  max-width: 1200px;
}
