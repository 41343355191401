.fab-container {
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 1040;
}

.actionButton {
  width: 40px !important;
  height: 40px !important;
  margin-top: 10px !important;
  background-color: #fe7339 !important;
  color: #fff !important;
}

.mainFab {
  width: 45px !important;
  height: 45px !important;
  margin-top: 10px !important;
  background-color: #fe7339 !important;
  color: #fff !important;
}
